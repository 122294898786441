import React from "react"
import BackgroundImage from "gatsby-background-image"
import { bg } from "./HeroBackgroundImageBase.module.scss"

export const HeroBackgroundImageBase = ({ title, id, imageData, children }) => {
  return (
    <BackgroundImage className={bg} fluid={imageData} title={title} id={id}>
      {children}
    </BackgroundImage>
  )
}
