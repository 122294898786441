import React from "react"
import { ContentSection } from "../content/index/contentSection/ContentSection"
import { Hero } from "../content/index/Hero"
import { TimeLineSection } from "../content/index/timeLineSection/TimeLineSection"

const IndexPage = () => {
  return (
    <>
      <Hero />
      <ContentSection />
      <TimeLineSection />
    </>
  )
}

export default IndexPage
