// extracted by mini-css-extract-plugin
export var cls1 = "Modernise-module--cls1--1qKrt";
export var cls10 = "Modernise-module--cls10--2at7N";
export var cls11 = "Modernise-module--cls11--imumo";
export var cls12 = "Modernise-module--cls12--3MSz8";
export var cls13 = "Modernise-module--cls13--2Frqh";
export var cls14 = "Modernise-module--cls14--2bzHr";
export var cls15 = "Modernise-module--cls15--3swGH";
export var cls16 = "Modernise-module--cls16--1PrIf";
export var cls6 = "Modernise-module--cls6--2KCNm";
export var cls2 = "Modernise-module--cls2--2HVIa";
export var cls3 = "Modernise-module--cls3--3Cjq4";
export var cls4 = "Modernise-module--cls4--3MABr";
export var cls5 = "Modernise-module--cls5--3PN9b";
export var cls17 = "Modernise-module--cls17--2rf21";
export var cls18 = "Modernise-module--cls18--2sPBK";
export var cls7 = "Modernise-module--cls7--3SQJU";
export var cls8 = "Modernise-module--cls8--3Wgr6";
export var cls9 = "Modernise-module--cls9--3WP2p";
export var cls19 = "Modernise-module--cls19--3pQ28";