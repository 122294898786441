import React from "react"
import { h1, h2, h3, h4, h5, h6, noMargin } from "./Heading.module.scss"

export const Heading = ({ variant, size, children, bottomMargin = true }) => {
  size = size ? size : variant

  let sizeClass
  switch (size) {
    case "h1":
      sizeClass = h1
      break
    case "h2":
      sizeClass = h2
      break
    case "h3":
      sizeClass = h3
      break
    case "h4":
      sizeClass = h4
      break
    case "h5":
      sizeClass = h5
      break
    case "h6":
      sizeClass = h6
      break
    default:
      sizeClass = h1
      break
  }

  if (!bottomMargin) {
    sizeClass = `${sizeClass} ${noMargin}`
  }

  switch (variant) {
    case "h1":
      return <h1 className={sizeClass}>{children}</h1>
    case "h2":
      return <h2 className={sizeClass}>{children}</h2>
    case "h3":
      return <h3 className={sizeClass}>{children}</h3>
    case "h4":
      return <h4 className={sizeClass}>{children}</h4>
    case "h5":
      return <h5 className={sizeClass}>{children}</h5>
    case "h6":
      return <h6 className={sizeClass}>{children}</h6>
    default:
      return <h1 className={sizeClass}>{children}</h1>
  }
}
