import React from "react"
import { dot, dots } from "../Timeline.module.scss"

export const Dots = () => {
  return (
    <ul className={dots}>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </ul>
  )
}

const Dot = () => {
  return (
    <li
      data-sal="fade"
      data-sal-delay="100"
      data-sal-duration="300"
      className={dot}
    ></li>
  )
}
