import React from "react"
import {
  container,
  button,
  label,
  arrow,
  o1,
  o2,
  background,
} from "./ContactUsButton.module.scss"
import { Link } from "gatsby"

export const ContactUsButton = ({ height, width }) => {
  height = height ? height : 55
  width = width ? width : 250

  return (
    <div className={container}>
      <button className={button}>
        <div className={label}>
          <Link to="/contact">
            <span>Get in Touch</span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="25"
            viewBox="0 0 30 9"
          >
            <path
              className={arrow}
              d="M2553,444h27.21l-7.5-7.5"
              transform="translate(-2553.778 -436.738)"
            />
          </svg>
        </div>

        <svg className={background}>
          <polyline
            className={o1}
            points={`0 0, ${width} 0, ${width} ${height}, 0 ${height}, 0 0`}
          ></polyline>
          <polyline
            className={o2}
            points={`0 0, ${width} 0, ${width} ${height}, 0 ${height}, 0 0`}
          ></polyline>
        </svg>
      </button>
    </div>
  )
}
