import React from "react"
import { Section } from "../../../layout/surfaces/Section"
import { Container } from "../../../layout/surfaces/Container"
import { Heading } from "../../../components/typogarphy/Heading"
import { Paragraph } from "../../../components/typogarphy/Paragraph"
import { Timeline, TimelinePoint } from "../../../components/timeline/Timeline"
import { heading } from "./TimeLineSection.module.scss"

export const TimeLineSection = () => {
  return (
    <Section id="timeline">
      <Container>
        <div className={heading}>
          <Heading variant="h2">So... How Does It Work?</Heading>
          <Paragraph type="light">
            Projects can iterate through many stages; planning, requirements and
            feasiblity analysis, design, development, testing and integration,
            implementation and deployment, and finally, ongoing maintenance.
          </Paragraph>
        </div>
        <Timeline>
          <TimelinePoint
            heading="Planning"
            subHeading="Initial planning phase"
            body="Although this is at the beginning phase of the project, it is also 
            a continuous part of a project.  We carry out meetings with relevant stakeholders 
            to discuss overall business needs, existing issues and put forward options and 
            make decisions on the best solutions."
          />
          <TimelinePoint
            heading="Requirements and Feasiblity Analysis"
            subHeading="Collaboration, stakeholder engagement and rough design specification"
            body="Every project is unique, at Deyst we understand that having clear goals and a firm grasp of 
            the resources available to achieve them is the key to a successful project. Working closely with 
            project stakeholders we define the scope, resource and infrastructure requirements that will best 
            fit the solution."
          />
          <TimelinePoint
            heading="Design"
            subHeading="Collaboration, prototypes, design specification reviews and updates"
            body="From idea to specification, using all the information gathered during our feasibility 
            analysis we draft design documentation that will be the foundation of the solution. The documents 
            will illustrate key components of the solution and how they fit together so you know exactly 
            what you will be getting before development begins. In addition, we understand the fluid nature of business 
            so if you discover something that you would like changed or added after reviewing the design, we 
            can accommodate."
          />
          <TimelinePoint
            heading="Development"
            subHeading="Building the application you want"
            body="In this phase of the process, we write the code.  Without the industry knowledge or know-how, it can be 
            difficult for businesses to understand what this phase entails, however we value ourselves on being as 
            transparent as possible during the development cycle and utilize collaboration tools that help you stay involved."
          />
          <TimelinePoint
            heading="Testing and Integration"
            subHeading="Putting together the pieces"
            body="Quality is king and we put testing at every level of our solutions from simple unit tests all 
            the way up to integration and user testing.  Testing offers the comfort of knowing something is 
            going to work as intended.  While we can’t test for every possible outcome 
            we will work closely with you to ensure there are no major issues."
          />
          <TimelinePoint
            heading="Implementation and deployment"
            subHeading="Your idea, fully realized"
            body="Once satisfied with testing we can deploy the solution into production.  We will do the final handover
            which will include all documents specified in the contract such as design documents, user guides and source code.
            You will own your idea."
          />
          <TimelinePoint
            heading="Maintenance"
            subHeading="As desired by the client; support, feedback, enhancements"
            body="Technology and business evolve and our solutions can evolve with them. We offer a range of 
            support options from one-off upgrades to ongoing monthly support.  Have feature requests? 
            Found a new technology that you want to incorporate?  We can do it for you."
          />
        </Timeline>
      </Container>
    </Section>
  )
}
