import React from "react"
import { btn } from "./TransparentButton.module.scss"

export const TransparentButton = props => {
  return (
    <button {...props} className={btn}>
      {props.children}
    </button>
  )
}
