import React from "react"
import { Heading } from "../typogarphy/Heading"
import { Paragraph } from "../typogarphy/Paragraph"
import { MoveButtons } from "../buttons/MoveButtons"
import { point, head, moveButtons, timeline } from "./Timeline.module.scss"
import { Dots } from "./components/Dots"

export const TimelinePoint = React.forwardRef((props, ref) => {
  const { heading, subHeading, body, position, prevRef, nextRef, toTop } = props
  const direction = position === "right" ? "slide-left" : "slide-right"

  return (
    <div
      data-sal={direction}
      data-sal-delay="100"
      data-sal-duration="300"
      data-sal-easing="easeInCubic"
      className={point}
      ref={ref}
    >
      <div className={head}>
        <Heading variant="h3" bottomMargin={false}>
          {heading}
        </Heading>
      </div>
      <Heading variant="h4">{subHeading}</Heading>
      <Paragraph>{body}</Paragraph>
      <div className={moveButtons}>
        <MoveButtons nextRef={nextRef} prevRef={prevRef} toTop={toTop} />
      </div>
    </div>
  )
})

export const Timeline = props => {
  const pointRefs = []
  for (let i = 0; i < props.children.length; i++) {
    pointRefs.push(React.createRef())
  }

  const points = React.Children.map(props.children, (child, i) => {
    const position = i % 2 === 0 ? "left" : "right"
    const prevRef = pointRefs[i - 1] //The ref for the previous timeline point
    const thisRef = pointRefs[i] //The ref for this timeline point
    const nextRef = pointRefs[i + 1] //The ref for the next point
    const toTopRef =
      i === props.children.length - 1
        ? pointRefs[props.children.length - props.children.length]
        : null //The ref for the top point

    const point = React.cloneElement(child, {
      position: position,
      ref: thisRef,
      prevRef: prevRef,
      nextRef: nextRef,
      toTop: toTopRef,
    })

    if (i < props.children.length - 1) {
      return (
        <>
          {point}
          <Dots />
        </>
      )
    }
    return point
  })

  return <div className={timeline}>{points}</div>
}
