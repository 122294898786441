// extracted by mini-css-extract-plugin
export var cls1 = "Head-module--cls1--2zzLR";
export var cls12 = "Head-module--cls12--2r34F";
export var cls2 = "Head-module--cls2--1Q2ER";
export var cls3 = "Head-module--cls3--1r2ov";
export var cls4 = "Head-module--cls4--1fAxw";
export var cls5 = "Head-module--cls5--WJGbf";
export var cls6 = "Head-module--cls6--siwVs";
export var cls7 = "Head-module--cls7--18Tti";
export var cls8 = "Head-module--cls8--3pzY4";
export var cls9 = "Head-module--cls9--2lD1J";
export var cls10 = "Head-module--cls10--2V0YZ";
export var cls13 = "Head-module--cls13--Mv0XU";
export var cls11 = "Head-module--cls11--1K4ew";