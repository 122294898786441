// extracted by mini-css-extract-plugin
export var cls1 = "Data-module--cls1--1tsjH";
export var cls11 = "Data-module--cls11--1ICa8";
export var cls6 = "Data-module--cls6--3zYre";
export var cls2 = "Data-module--cls2--QIUEU";
export var cls3 = "Data-module--cls3--26A4Y";
export var cls4 = "Data-module--cls4--2wnAy";
export var cls5 = "Data-module--cls5--1F2xo";
export var cls12 = "Data-module--cls12--2K9Am";
export var cls7 = "Data-module--cls7--1MUUf";
export var cls8 = "Data-module--cls8--2bvHe";
export var cls9 = "Data-module--cls9--gAhCR";
export var cls10 = "Data-module--cls10--397pU";