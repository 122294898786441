import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { HeroBackgroundImageBase } from "../background/base/HeroBackgroundImageBase"

const query = graphql`
  query {
    background: file(relativePath: { eq: "hero-index-bg.png" }) {
      ...fluidBackground
    }
  }
`

export const HomeHeroImage = ({ children }) => {
  const { background } = useStaticQuery(query)
  const imageData = background.childImageSharp.fluid
  return (
    <HeroBackgroundImageBase
      title="Deyst Solutions Home Page"
      id="Deyst Solutions Home Page"
      imageData={imageData}
      credit="Photo by Majharul Islam on Unsplash "
      contentType="landing"
    >
      {children}
    </HeroBackgroundImageBase>
  )
}

//<span>Photo by <a href="https://unsplash.com/@jaccobm?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Jaccob McKay</a> on <a href="https://unsplash.com/s/photos/australia?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
