import React from "react"
import { TransparentButton } from "./base/TransparentButton"
import { btns, topButton, arrowDown, arrowUp } from "./MoveButtons.module.scss"

const scrollToRef = ref => {
  ref.current.scrollIntoView({ block: "center", behavior: "smooth" })
}

export const MoveButtons = ({ nextRef, prevRef, toTop }) => {
  if (toTop) {
    return (
      <div className={btns}>
        <TransparentButton onClick={() => scrollToRef(toTop)}>
          <span className={topButton}>back to top</span>
        </TransparentButton>
      </div>
    )
  } else {
    return (
      <div className={btns}>
        {nextRef ? (
          <TransparentButton onClick={() => scrollToRef(nextRef)}>
            <span className={arrowDown}></span>
          </TransparentButton>
        ) : null}

        {prevRef ? (
          <TransparentButton onClick={() => scrollToRef(prevRef)}>
            <span className={arrowUp}></span>
          </TransparentButton>
        ) : null}
      </div>
    )
  }
}

export const HeroDownButton = ({ nextRef }) => {
  return (
    <TransparentButton onClick={() => scrollToRef(nextRef)}>
      <span className={arrowDown}></span>
    </TransparentButton>
  )
}
