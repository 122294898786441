import React from "react"
import { Section } from "../../../layout/surfaces/Section"
import { Container } from "../../../layout/surfaces/Container"
import { ContentGrid, ContentTile, ContentDivider } from "./ContentTile"
import { Paragraph } from "../../../components/typogarphy/Paragraph"

import { Head } from "../../../assets/svg/vectors/Head"
import { Data } from "../../../assets/svg/vectors/Data"
import { Modernise } from "../../../assets/svg/vectors/Modernise"

export const ContentSection = () => {
  return (
    <Section>
      <Container>
        <ContentGrid>
          <ContentDivider>
            <Head />
          </ContentDivider>
          <ContentTile heading="Integrate business systems">
            <Paragraph>
              <strong>
                Having trouble sharing important data between business systems
                and applications?
              </strong>
            </Paragraph>
            <Paragraph>
              We can design and implement an integration solution to automate
              the synchronisation of data across systems. With the power of
              automation, you can streamline every day tasks to facilitate
              profit maximisation, gain competitive advantage and reduce
              administrative overhead.
            </Paragraph>
            <Paragraph>
              If you'd like to know more, contact our team today.
            </Paragraph>
          </ContentTile>

          <ContentDivider>
            <Data />
          </ContentDivider>
          <ContentTile heading="Manage your data better">
            <Paragraph>
              <strong>Are you using Excel to store precious data?</strong>
            </Paragraph>
            <Paragraph>
              Information management is vital to business survival in a
              Globalised world, however many businesses are still unable to
              manage their data effectively. This is where we can help; we
              specialise in developing and designing web applications which can
              assist in reducing human error and storing data more securely.
            </Paragraph>
            <Paragraph>
              Contact us today to see how we can get rid of your Excel
              spreadsheets and replace them with a more robust and efficient way
              to maintain and store your information.
            </Paragraph>
          </ContentTile>

          <ContentDivider>
            <Modernise />
          </ContentDivider>
          <ContentTile heading="Modernise your IT landscape">
            <Paragraph>
              <strong>
                “It is only when they go wrong that machines remind you how
                powerful they are.” --Clive James, Broadcaster and Journalist.
              </strong>
            </Paragraph>
            <Paragraph>
              Technology is ever evolving and fast-paced; falling behind can not
              only reduce competitive advantage but pose as a risk to your
              business. We can review your business technology needs through
              systems workflow analysis and provide advice, recommendations or
              develop solutions to ensure that your business isn't falling
              behind.
            </Paragraph>
            <Paragraph>
              Don't waste your time trying to keep up; let us do it for you. Get
              in contact with our team today.
            </Paragraph>
          </ContentTile>
        </ContentGrid>
      </Container>
    </Section>
  )
}
