import React from "react"
import { section, light } from "./Section.module.scss"

export const Section = React.forwardRef(
  ({ id, children, bg = "dark" }, ref) => {
    const classes = bg === "dark" ? section : `${section} ${light}`
    return (
      <section className={classes} id={id} ref={ref}>
        {children}
      </section>
    )
  }
)
