import React from "react"
import { HomeHeroImage } from "../../components/images/hero/HomeHeroImage"
import { ContactUsButton } from "../../components/buttons/ContactUsButton"
import { Heading } from "../../components/typogarphy/Heading"
import { Container } from "../../layout/surfaces/Container"
import { Section } from "../../layout/surfaces/Section"
import { content } from "./Hero.module.scss"

export const Hero = () => {
  return (
    <Section id="home_hero">
      <HomeHeroImage name={"landing"}>
        <Container>
          <div className={content}>
            <Heading variant="h1">Your Ideas</Heading>
            <Heading variant="h1">Our Solutions</Heading>
            <Heading variant="h3" size="h6">
              Systems integration, business intelligence and application
              development for your growing business. Talk with our team today to
              discuss how we can help you realise your digital transformation
              goals
            </Heading>
            <ContactUsButton />
          </div>
        </Container>
      </HomeHeroImage>
    </Section>
  )
}
