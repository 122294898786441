import React from "react"
import { Heading } from "../../../components/typogarphy/Heading"

import { grid, tile, divider } from "./ContentTile.module.scss"

export const ContentTile = ({ heading, ...props }) => {
  return (
    <div className={tile}>
      <Heading variant="h3">{heading}</Heading>
      {props.children}
    </div>
  )
}

export const ContentDivider = ({ ...props }) => {
  return <div className={divider}>{props.children}</div>
}

export const ContentGrid = props => {
  return <div className={grid}>{props.children}</div>
}
